import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageFeedback } from '~/models'
import { BrandedComponent, ClearButton, HBox, PushButton, VBox } from '~/ui/components'
import { animation, createUseStyles, layout, useStyling } from '~/ui/styling'
import { useChat } from '../ChatContext'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback

  maySubmit?:     boolean
  requestSubmit?: () => any

  stretch?:      boolean
  bubbleLayout?: 'row' | 'column'
  children?:     React.ReactNode
}

const FeedbackBubble = (props: Props) => {

  const {
    messageID,
    feedback,
    maySubmit = true,
    requestSubmit,
    stretch = true,
    bubbleLayout = 'column',
    children,
  } = props

  const {service: {currentChat}} = useChat()
  const [t]       = useTranslation('chat')

  //------
  // Callbacks

  const skip = React.useCallback(() => {
    if (messageID == null) { return }
    currentChat?.sendMessage({
      type:    'skip',
      replyTo: messageID,
    })
  }, [currentChat, messageID])

  //------
  // Rendering

  const $ = useStyles()

  const {guide} = useStyling()

  function render() {
    return (
      <BrandedComponent branding={guide.chat.bubble} variant={{feedback: true}} height={guide.chat.bubble.minHeight}>
        <VBox classNames={[$.feedbackBubble, {stretch}]}>
          {bubbleLayout === 'row' ? (
            renderRowLayout()
          ) : (
            renderColumnLayout()
          )}
        </VBox>
      </BrandedComponent>
    )
  }

  function renderRowLayout() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex>
          {children}
        </VBox>
        {renderButtons()}
      </HBox>
    )
  }

  function renderColumnLayout() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <VBox>
          {children}
        </VBox>
        {renderButtons()}
      </VBox>
    )
  }

  function renderButtons() {
    if (requestSubmit == null && !feedback.skippable) { return null }

    if (bubbleLayout === 'column') {
      return (
        <HBox gap={layout.padding.inline.m} justify='right'>
          {renderSubmitButton()}
          {renderSkipButton()}
        </HBox>
      )
    } else {
      return (
        <VBox align='center'>
          {renderSubmitButton()}
          {renderSkipButton()}
        </VBox>
      )
    }
  }

  function renderSubmitButton() {
    if (requestSubmit == null) { return null }

    return (
      <PushButton
        caption={t('feedback.submit')}
        onTap={requestSubmit}
        enabled={maySubmit}
        small
      />
    )
  }

  function renderSkipButton() {
    if (!feedback.skippable) { return null }

    return (
      <ClearButton
        caption={feedback.skipText ?? t('feedback.skip')}
        onTap={skip}
        small
        dim
      />
    )
  }

  return render()

}

export default FeedbackBubble

export const padding = layout.padding.inline.m

const useStyles = createUseStyles(theme => ({
  feedbackBubble: {
    overflow:     'hidden',

    willChange: ['width'],
    transition: animation.transitions.short(['width']),

    maxWidth: theme.guide.chat.bubble.maxWidth,
    '&.stretch': {
      width: theme.guide.chat.bubble.maxWidth,
    },

    minHeight:    theme.guide.chat.bubble.minHeight,
    padding:      padding,
  },
}))