import React from 'react'
import { MessageListItem } from '~/models'
import { ChatService } from '~/stores/chat'
import { observer } from '~/ui/component'
import { ChatBubbleBranding } from '~/ui/styling'

interface ChatContext {
  service: ChatService | null

  showChannelInfo:    boolean
  setShowChannelInfo: (value: boolean) => void

  brandingForMessage: (message: MessageListItem) => ChatBubbleBranding | null
  onMessageTap?:      (message: MessageListItem) => any
}

const ChatContext = React.createContext<ChatContext>({
  service: null,

  showChannelInfo:    false,
  setShowChannelInfo: () => void 0,

  brandingForMessage: () => null,
})

export interface Params {
  channelID?: string
}

export interface ChatContextProviderProps {
  service:  ChatService
  children: React.ReactNode

  brandingForMessage?: (message: MessageListItem) => ChatBubbleBranding | null
  onMessageTap?:       (message: MessageListItem) => any
}

export const ChatContextProvider = observer('ChatContextProvider', (props: ChatContextProviderProps) => {

  const {service, children, brandingForMessage, onMessageTap} = props
  const [showChannelInfo, setShowChannelInfo] = React.useState<boolean>(false)

  const context = React.useMemo(() => ({
    service:            service,
    showChannelInfo:    showChannelInfo,
    setShowChannelInfo: setShowChannelInfo,
    brandingForMessage: brandingForMessage ?? (() => null),
    onMessageTap:       onMessageTap,
  }), [service, showChannelInfo, brandingForMessage, onMessageTap])

  return (
    <ChatContext.Provider value={context}>
      {children}
    </ChatContext.Provider>
  )
})

export function useChat(): ChatContext & {service: ChatService} {
  const context = React.useContext(ChatContext)
  if (context.service == null) {
    throw new Error('useChat() must be used within a ChatContextProvider')
  }

  return context as ChatContext & {service: ChatService}
}

export default ChatContextProvider