import React from 'react'
import { DropzoneState, FileRejection, useDropzone } from 'react-dropzone'
import { isFunction } from 'lodash'
import config from '~/config'
import { acceptedFeedbackMediaMimeTypes, FeedbackMediaType } from '~/models'
import { ChatBackend } from '~/stores/chat'
import { VBox, VBoxProps } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import processMediaUpload from '../media/processMediaUpload'
import { useChat } from './ChatContext'

export interface Props {
  backend:  ChatBackend | null
  allowed?: FeedbackMediaType[]
  replyTo?: string | null
  enabled?: boolean | 'drag-only'

  maxSize?: number

  flex?:        VBoxProps['flex']
  classNames?:  React.ClassNamesProp

  children?:    React.ReactNode | ((state: ChatMediaUploaderState) => React.ReactNode)
}

export type ChatMediaUploaderState = Omit<DropzoneState, 'getRootProps' | 'getInputProps' | 'open'>
export interface ChatMediaUploaderHandle {
  browse: () => any
}

const ChatMediaUploader = React.forwardRef((props: Props, ref: React.Ref<ChatMediaUploaderHandle>) => {

  const {
    backend,
    allowed,
    maxSize = config.mediaUploader.maxFileSize,
    replyTo,
    enabled = true,
    flex,
    classNames,
    children,
  } = props

  const { service: chat } = useChat()
  const supported = chat?.buildPendingMediaMessageTemplate != null

  //------
  // Callbacks

  const submit = React.useCallback(async (file: File) => {
    const template = await chat?.buildPendingMediaMessageTemplate?.(file, allowed ?? FeedbackMediaType.all)
    if (template == null) { return }

    backend?.sendMessage({...template, replyTo: replyTo ?? null})
  }, [allowed, chat, backend, replyTo])

  const handleDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) { return }
    submit(acceptedFiles[0])
  }, [submit])

  const handleDropRejected = React.useCallback((rejections: FileRejection[]) => {
    const error = rejections[0]?.errors?.[0]
    if (error.code === 'file-too-large') {
      processMediaUpload({status: 'invalid', reason: 'too-large'})
    }
  }, [])

  const {getRootProps, getInputProps, open, ...state} = useDropzone({
    accept:         acceptedFeedbackMediaMimeTypes(allowed ?? FeedbackMediaType.all),
    onDrop:         handleDrop,
    onDropRejected: handleDropRejected,
    maxSize:        maxSize,
    noClick:        enabled !== true,
    noKeyboard:     enabled !== true,
    noDrag:         enabled === false,
  })

  React.useImperativeHandle(ref, (): ChatMediaUploaderHandle => ({
    browse: open,
  }), [open])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!supported) {
      return renderChildren()
    } else {
      return (
        <VBox {...getRootProps()} flex={flex} classNames={[$.chatUploader, classNames]}>
          <input {...getInputProps()}/>
          <VBox flex={flex}>
            {renderChildren()}
          </VBox>
        </VBox>
      )
    }
  }

  function renderChildren() {
    return (
      <>
        {isFunction(children) ? children(state) : children}
      </>
    )
  }

  return render()

})

export default ChatMediaUploader

const useStyles = createUseStyles({
  chatUploader: {
    '&:focus': {
      outline: 'none',
    },
  },
})