import React from 'react'
import { useTranslation } from 'react-i18next'
import { feedbackMediaTypeForMimeType, MediaFeedback, MessageFeedback } from '~/models'
import { Label, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import { useChat } from '../ChatContext'
import ChatMediaUploader, { ChatMediaUploaderState } from '../ChatMediaUploader'
import FeedbackBubble from './FeedbackBubble'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback & MediaFeedback
}

const MediaFeedbackBubble = React.memo((props: Props) => {

  const {messageID, feedback} = props

  const {service: {currentChat}} = useChat()
  const enabled   = messageID != null


  const [t] = useTranslation('chat')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <FeedbackBubble messageID={messageID} feedback={feedback}>
        <ChatMediaUploader
          replyTo={messageID}
          backend={currentChat}
          children={renderBody}
          classNames={$.uploader}
          enabled={enabled}
        />
      </FeedbackBubble>
    )
  }

  function renderBody(state: ChatMediaUploaderState) {
    const classNames = [
      $.body,
      {active: state.isDragActive},
      {accept: state.isDragAccept},
      {reject: state.isDragReject},
    ]

    const scope = translateScope(state)
    return (
      <VBox justify='middle' gap={layout.padding.inline.m} classNames={classNames}>
        <Label caption bold align='center'>
          {t(`${scope}.title`)}
        </Label>
        <Label caption small align='center' truncate={false}>
          {t(`${scope}.detail`)}
        </Label>
      </VBox>
    )
  }

  const translateScope = React.useCallback((state: ChatMediaUploaderState): string | null => {
    if (state.draggedFiles.length === 0) {
      const typeScope =
        feedback.mediaTypes.length === 2 ? 'both' :
        feedback.mediaTypes.includes('video') ? 'video' : 'image'

      return `feedback.media.dropzone.idle.${typeScope}`
    }

    const mimeType    = state.draggedFiles[0].type
    const typeScope   = feedbackMediaTypeForMimeType(mimeType, true)
    const acceptScope = state.isDragReject ? 'reject' : state.isDragAccept ? 'accept' : 'inactive'

    if (typeScope == null) {
      return `feedback.media.dropzone.reject`
    } else {
      return `feedback.media.dropzone.${acceptScope}.${typeScope}`
    }
  }, [feedback.mediaTypes])

  return render()

})

export default MediaFeedbackBubble

const useStyles = createUseStyles(theme => ({
  uploader: {
    cursor:       'pointer',
    border:       [2, 'dashed', theme.colors.fg.light.dimmer],
    borderRadius: layout.radius.m,

    '&:hover': {
      background: theme.colors.bg.light.alt.alpha(0.1),
    },
    '&:focus': {
      boxShadow: shadows.focus.bold(theme),
    },
  },

  body: {
    height:  120,
    padding: layout.padding.inline.m,

    '&.accept': {
      background: theme.semantic.positive.lighten(0.8).alpha(0.4),
    },
    '&.reject': {
      background: theme.semantic.negative.lighten(0.8).alpha(0.4),
    },
  },
}))