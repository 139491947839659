import React from 'react'
import { useTranslation } from 'react-i18next'
import { VirtualizedChatView } from 'react-virtualized-chat'
import { ChatBackend } from '~/stores/chat'
import { observer } from '~/ui/component'
import { Center, Empty, Label, Spinner, VBox } from '~/ui/components'
import { colors, createUseStyles, layout } from '~/ui/styling'
import ChatInputBar from './ChatInputBar'
import ChatMediaUploader, {
  ChatMediaUploaderHandle,
  ChatMediaUploaderState,
} from './ChatMediaUploader'
import ChatMessageListView, { Props as ChatMessageListViewProps } from './ChatMessageListView'

export interface Props {
  backend:        ChatBackend
  timestamps?:    boolean
  previewing?:    boolean
  forceOpen?:     boolean
  renderMessage?: ChatMessageListViewProps['renderMessage']
  empty?:         {title: string, detail: string}
}

const ChatView = observer('ChatView', (props: Props) => {

  const {
    backend,
    timestamps = true,
    previewing = false,
    forceOpen,
    renderMessage,
    empty,
  }  = props

  const descriptor     = backend.descriptor
  const messageList = backend.messageList

  const openChannel = forceOpen != null ? forceOpen : descriptor.open

  React.useEffect(() => {
    if (backend.unreadCount > 0) {
      backend.markAsRead()
    }
  }, [backend, backend.unreadCount])

  const fetchMoreMessages = React.useCallback(() => {
    backend.fetchNextPage()
  }, [backend])

  const mediaUploaderRef = React.useRef<ChatMediaUploaderHandle>(null)
  const upload = React.useCallback(() => {
    mediaUploaderRef.current?.browse()
  }, [])

  const [t] = useTranslation('chat')

  const listRef = React.useRef<VirtualizedChatView>(null)

  React.useEffect(() => {
    if (openChannel) {
      listRef.current?.scrollToBottom('smooth')
    }
  }, [openChannel])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ChatMediaUploader
        ref={mediaUploaderRef}
        backend={backend}
        children={renderUploaderBody}
        enabled={(!previewing && descriptor.open) ? 'drag-only' : false}
        flex
      />
    )
  }

  function renderUploaderBody(state: ChatMediaUploaderState) {
    return (
      <VBox flex>
        {renderContent()}
        {renderUploaderOverlay(state)}
      </VBox>
    )
  }

  function renderContent() {
    return (
      <VBox flex>
        {messageList.count > 0 ? (
          <VBox flex>
            {renderMessageList()}
          </VBox>
        ) : empty != null ? (
          <Empty flex {...empty}/>
        ) : null}
        {openChannel && renderInputBar()}
      </VBox>
    )
  }

  function renderMessageList() {
    if (messageList == null) {
      return (
        <Center flex>
          <Spinner dim/>
        </Center>
      )
    }

    if (previewing) {
      return (
        <Empty
          flex
          {...t('previewing')}
        />
      )
    }

    return (
      <ChatMessageListView
        listRef={listRef}
        chat={descriptor}
        backend={backend}
        timestamps={timestamps}
        messageList={messageList}
        onEndReached={fetchMoreMessages}
        renderMessage={renderMessage}
      />
    )
  }

  function renderInputBar() {
    return (
      <VBox flex={messageList.count === 0 && empty == null} justify='bottom'>
        <ChatInputBar
          requestUpload={upload}
          enabled={!previewing}
        />
      </VBox>
    )
  }

  function renderUploaderOverlay(state: ChatMediaUploaderState) {
    if (!state.isDragActive) { return null }

    const scope = state.isDragReject ? 'reject' : 'accept'
    const classNames = [
      $.mediaUploaderOverlay,
      {active: state.isDragActive},
      {accept: state.isDragAccept},
      {reject: state.isDragReject},
    ]

    return (
      <Center classNames={classNames}>
        <Label caption>
          {t(`input_bar.drop_hint.${scope}`)}
        </Label>
      </Center>
    )
  }

  return render()

})

export default ChatView

const useStyles = createUseStyles(theme => ({
  mediaUploaderOverlay: {
    ...layout.overlay,

    '&.accept': {
      ...colors.overrideBackground(theme.semantic.positive.alpha(0.4)),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.positive.alpha(0.4))),
    },
    '&.reject': {
      ...colors.overrideBackground(theme.semantic.negative.alpha(0.4)),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.negative.alpha(0.4))),
    },
  },
}))