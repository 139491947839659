import React from 'react'
import { Message } from '~/models'
import { ChatBackend } from '~/stores/chat'
import { memo } from '~/ui/component'
import { ClearButton, HBox, PushButton } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'

export interface Props {
  message: Message
  backend: ChatBackend
}

const ChatMessageModeration = memo('ChatMessageModeration', (props: Props) => {

  const {message, backend} = props
  const needsModeration = message.approvedAt === null

  const theme = useTheme()

  const approve = React.useCallback(() => {
    backend.approveMessage?.(message.id)
  }, [backend, message.id])

  const redact = React.useCallback(() => {
    backend.redactMessage?.(message.id)
  }, [backend, message.id])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.s}>
        {needsModeration && (
          <PushButton
            icon='check'
            color={theme.semantic.positive}
            onTap={approve}
            small
          />
        )}
        {needsModeration && (
          <PushButton
            icon='cross'
            color={theme.semantic.negative}
            onTap={redact}
            small
          />
        )}
        {!needsModeration && (
          <ClearButton
            icon='cross'
            color={theme.semantic.negative}
            onTap={redact}
            padding='both'
            small
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default ChatMessageModeration