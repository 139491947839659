import React from 'react'
import { RenderMessageInfo, VirtualizedChatView } from 'react-virtualized-chat'
import { MessageList } from '~/models'
import { ChatBackend, ChatDescriptor } from '~/stores/chat'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ChatMessageListItem, { Props as ChatMessageListItemProps } from './ChatMessageListItem'

export interface Props {
  chat:        ChatDescriptor
  backend:     ChatBackend
  messageList: MessageList

  onEndReached?: () => any

  listRef?:      React.Ref<VirtualizedChatView>

  renderMessage?: ChatMessageListItemProps['render']
  timestamps:     boolean
}

const ChatMessageListView = observer('ChatMessageListView', (props: Props) => {

  const {
    chat,
    backend,
    messageList,
    onEndReached,
    renderMessage: props_renderMessage,
    listRef,
    timestamps,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex ref={containerRef}>
        {renderList()}
      </VBox>
    )
  }

  function renderList() {
    return (
      <VirtualizedChatView
        ref={listRef}
        containerClassName={$.list}
        contentPadding={layout.padding.inline.m}
        keys={messageList.messageIDsDescending}
        renderMessage={renderMessage}
        onEndReached={onEndReached}
      />
    )
  }

  const renderMessage = React.useCallback((info: RenderMessageInfo) => (
    <ChatMessageListItem
      key={messageList.messageIDsDescending[info.index]}
      chat={chat}
      backend={backend}
      messageList={messageList}
      timestamp={timestamps}
      render={props_renderMessage}
      index={info.index}
      itemRef={info.itemRef}
      style={info.style}
    />
  ), [backend, chat, messageList, props_renderMessage, timestamps])

  return render()

})

export default ChatMessageListView

const useStyles = createUseStyles({
  list: {
    flex: [1, 0, 0],
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
})